<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2021-04-06 15:41:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="addCity box-create">
        <p style=""></p>
        <div class="box-title">{{$t('system.city.add')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="1">
                    <el-divider content-position="center">{{$t('project.regular-data')}}</el-divider>
                    <el-form-item :label="$t('system.city.add')"  prop="cityNameInternational" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.cityNameInternational"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.region')" prop="regionID" :rules="[{ required: true, message: $t('remind.not-null')}]" >
                        <el-select v-model="form.regionID" @change="regionChange">
                            <el-option
                                    v-for="item in regions"
                                    :key="item.regionID"
                                    :label="item.regionNameInternational"
                                    :value="item.regionID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.country')" prop="countryID" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select v-model="form.countryID">
                            <el-option
                                    v-for="item in countrys"
                                    :key="item.countryID"
                                    :label="item.countryNameInternational"
                                    :value="item.countryID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.lon')" prop="longitude" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.longitude" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.lat')" prop="latitude" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.latitude" type="number" v-number></el-input>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('project.data.dailyRadiation')}}</el-divider>
                    <el-form-item :label="$t('January')" prop="dailyRadiation1" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation1" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('February')" prop="dailyRadiation2" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation2" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('March')" prop="dailyRadiation3" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation3" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('April')" prop="dailyRadiation4" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation4" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('May')" prop="dailyRadiation5" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation5" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('June')" prop="dailyRadiation6" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation6" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('July')" prop="dailyRadiation7" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation7" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('August')" prop="dailyRadiation8" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation8" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('September')" prop="dailyRadiation9" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation9" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('October')" prop="dailyRadiation10" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation10" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('November')" prop="dailyRadiation11" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation11" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('December')" prop="dailyRadiation12" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dailyRadiation12" type="number" v-number></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-divider content-position="center" style="margin-top:62px">{{$t('project.data.energy-data')}}</el-divider>
                    <el-form-item :label="$t('project.data.default-grid-voltage')" prop="defaultGridID">
                        <el-select v-model="form.defaultGridID">
                            <el-option :value="0" :label="$t('common.msg.pleaseselect')"></el-option>
                            <el-option :value="13" label="220V (220V / 380V)"></el-option>
                            <el-option :value="5" label="230V (230V / 400V)"></el-option>
                            <el-option :value="6" label="240V (240V / 415V)"></el-option>
                            <el-option :value="25" label="202V (101V / 202V)"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.inclination')" prop="inclinationProposal" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.inclinationProposal" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.azimuth-proposal')" prop="azimuthProposal" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.azimuthProposal" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.rated-power-ratio')" style="margin-bottom:58px"  required>
                        <el-col :span="11">
                            <el-form-item prop="nomPowerFrom" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                <el-input v-model="form.nomPowerFrom"  type="number" v-number @change='changeNomPowerform'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="nomPowerTo" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                <el-input v-model="form.nomPowerTo" type="number" v-number @change='changeNomPowerTo'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('project.data.avg-air-temp')}}</el-divider>
                    <el-form-item :label="$t('January')" prop="avgAirTemp1" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp1" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('February')" prop="avgAirTemp2" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp2" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('March')" prop="avgAirTemp3" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp3" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('April')" prop="avgAirTemp4" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp4" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('May')" prop="avgAirTemp5" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp5" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('June')" prop="avgAirTemp6" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp6" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('July')" prop="avgAirTemp7" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp7" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('August')" prop="avgAirTemp8" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp8" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('September')" prop="avgAirTemp9" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp9" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('October')" prop="avgAirTemp10" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp10" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('November')" prop="avgAirTemp11" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp11" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('December')" prop="avgAirTemp12" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.avgAirTemp12" type="number" v-number></el-input>
                    </el-form-item>
                </el-col>
       
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminCity'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        return {
            form:{
            },
            cityID: this.$route.query.id,
            regions:[],
            countrys:[],
            defaultGridIDs:[5,6,13],
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.cityID){
            this.systemTCityGetById()
        }
        this.getAllRegion()
        this.getAllCountry()

    },
    methods: {
        changeNomPowerform(){
            let nomPowerTo = this.form.nomPowerTo && parseInt(this.form.nomPowerTo)
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            this.form.nomPowerFrom = nomPowerFrom > nomPowerTo ? nomPowerTo - 1 :nomPowerFrom
        },
        changeNomPowerTo(){
            let nomPowerTo = this.form.nomPowerTo? parseInt(this.form.nomPowerTo) : ''
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            if((nomPowerTo <= nomPowerFrom) && nomPowerFrom && nomPowerTo){
                this.$message({
                    message: this.$t('nomPowerFrom.big'),
                    type: 'warning'
                });
                this.form.nomPowerTo = parseInt(nomPowerFrom) + 1
                nomPowerTo = parseInt(nomPowerFrom) + 1
            }
            if(nomPowerTo >150 || nomPowerTo  == 150){
                // console.log(this.form.nomPowerTo,'?');
                this.form.nomPowerTo = 149
            }else if(nomPowerTo <=100){
                this.form.nomPowerTo = 100
            }
        },
        /*获取详情数据*/
        async systemTCityGetById(){
            let res = await this.API.systemTCityGetById({
                cityID: this.cityID
            })

            if(this.defaultGridIDs.indexOf(res.defaultGridID)<0){
                res.defaultGridID = ''
            }
            this.form = res
        },

        /*区域选择值改变*/
        regionChange(id){
            this.$set(this.form , 'countryID' , '')
            this.getAllCountry(id)
        },

        /*获取所有区域*/
        async getAllRegion(){
            let res = await this.API.systemRegionsGetAll({})
            this.regions = res;
        },

        /*获取所有国家*/
        async getAllCountry(id){
            let res = await this.API.systemCountryGetAll({
                regionId: id
            })
            this.countrys = res
        },

        /*保存提交*/
        saveForm(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if($this.cityID){
                        $this.update($this.form)
                    }else {
                        $this.save($this.form)
                    }
                } else {
                    return false;
                }
            });
        },

        /*更新*/
        async update(data){
            let res = await this.API.systemTCityUpdate(data)
            this.showMessage(res)

        },

        /*创建*/
        async save(data){
            let res = await this.API.systemTCitySave(data)
            this.showMessage(res)
        },

        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminCity'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },


    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
    .el-form{
        .el-divider{
            white-space: nowrap;
        }
        .el-input-group__append, .el-input-group__prepend{
            padding: 0;
            width: 50px;
            text-align: center;
            font-size: 12px;
        }
        .el-input-group+.el-input-group{
            margin-left:10px;
        }
        .el-input-group.inline{
            width:112px;
        }
        .el-col-11,.el-col-2{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .el-col-2{
            text-align: center;
        }
    }
}
    
</style>

